import React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import "../styles/layout.scss";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ maxWidth: "400px", margin: "100px auto", padding: "0 4%" }}>
      <h1>404 - FANT IKKE SIDE</h1>
      <p>Du traff en side som ikke eksisterer..</p>
      <Link to="/">Tilbake til hjemmesiden</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
